import React from "react"
import { SiteMetadata } from "../components"
import { WavesSVG } from '../components/SVG/'
import { LayoutFull } from "../layouts/LayoutFull"
import { Container, Row, Col} from "react-bootstrap"
import { Link } from "gatsby"

export default () => {
  return (
    <LayoutFull>
      <SiteMetadata title="Ошибка 404" description="Страница не найдена..." canonical="/404/" />
      <WavesSVG down/>
      <Container className="my-3">
        <Row className="text-center">
          <Col xs={12} md={5}>
            <div className="beeright d-inline">Ошибка</div>
            <div style={{fontSize: '10rem'}}>404</div>
          </Col>
          <Col>
            <div className="mb-2">К сожалению запрашиваемая Вами страница не найдена...</div>
            <div className="mb-5">Но у нас есть много других =)</div>
            
            <div>
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `150px`}} to="/">Главная</Link>      
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `150px`}} to="/about/">О нас</Link>      
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `150px`}} to="/shop/">Все товары</Link>      
              <Link className="btn btn-primary btn-lg rounded mx-3 mb-3" style={{minWidth: `150px`}} to="/shop/med/">Все о меде</Link>  
            </div>
          </Col>
        </Row>
      </Container>
    </LayoutFull>
  )
}
